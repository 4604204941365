<template>
  <v-snackbar v-model="show">
    {{ message }}
    <template v-slot:action="{ attrs }">
      <v-btn text v-bind="attrs" @click="show = false">Закрыть</v-btn>
    </template>
  </v-snackbar>
</template>

<script>
export default {
  data: () => ({
    show: false,
    message: "",
  }),

  created() {
    this.$store.subscribe((mutation, state) => {
      if (mutation.type === "snackbar/SHOW") {
        this.message = state.snackbar.message;
        this.show = true;
      }
    });
  },
};
</script>