<template>
  <v-overlay :value="show">
    <v-progress-circular indeterminate></v-progress-circular>
  </v-overlay>
</template>

<script>
import { mapState } from "vuex";

export default {
  computed: mapState("progress", ["show"]),
};
</script>